import React from 'react';
import './App.css';
import OverlayImages from './OverlayImages';

function App() {
  return (
    <div className="App">
      <nav className="navbar">
        <h1>Traveler Information and Mobility Innovations for High-Visibility Events</h1>
      </nav>
      <header className="App-header">
        <p>High-visibility events are large-scale gatherings, such as the Olympic Games, sports championships, concerts, or international summits, that draw significant public attention and large crowds, often placing increased demands on transportation systems. Traveler information and mobility innovations for these events involve deploying advanced technologies and strategies to manage traffic flow, enhance public transit usage, and provide real-time information to travelers. These innovations aim to optimize mobility, safety, and accessibility during high-visibility events by leveraging tools such as digital message signs, event routes, robo taxis, and more. This visualization was developed using publicly available evaluation and research studies from the Benefits, Costs, and Lessons Learned Database and shows selected technologies that facilitate high-visibility event transportation management.</p>
        <i><strong>Tips for Using the Visualization:</strong> Hover your mouse over the graphic for each technology and click to learn more information.</i>
      </header>
      <div className='overlay-images'>
        <OverlayImages />
      </div>
    </div>
  );
}

export default App;
